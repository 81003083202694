.App {
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.light {
  background-color: ghostwhite;
  color: #202020;
}

.dark {
  background-color: #202020;
  color: ghostwhite;
}

/* Scrollbar 
::-webkit-scrollbar {
  background-color: #f1f1f1;
  /*width: 16px;
}

/* Scrollbar thumb 
::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
}

/* Scrollbar button 
::-webkit-scrollbar-button {
  background-color: #a8a8a8; 
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAP0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
  background-size: contain;
}
*/

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: all 0.5s;
  transition: all 0.5s;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
    background-color: #333;
    color: white;
    padding: 2px 0;
    text-align: center;
}

nav ul {
    list-style: none;
}

nav ul li {
    display: center;
    margin: 0 20px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

.section {
    padding: 50px;
    text-align: center;
}

.project {
    margin-top: 20px;
}


.banner {
  text-align: center;
  /*width: 822px; */
  height: 60px;
  float: center;
  margin-left: auto; 
  margin-right: auto; 
  margin: center; 
  border:0;
}

.banner-img {
  text-align: center;
  float: center;
  /*width: 822px;*/ 
  height: 246px;
}

.ip {
  text-align: center;
  font-family: Bahnschrift SemiBold SemiConden;
  font-weight: bold;
}

.vip-intro {
  text-align: center;
  /*width: 772px;*/
  border:3px;
  border-style:solid hidden hidden hidden;
  border-color: #000000;
}

.vip-intro-t {
  font-weight: bold; 
  font-family: Bahnschrift;
  line-height: 1
}

.prices {
 /* width: 822px; */
  height: 384px; 
  text-align: left; 
  margin-left: auto; 
  margin-right: auto;
  border: 0;
}

.prices-body {
  text-align: center;
  border:3px;
  border-style:solid solid hidden solid;
  border-color: #000000;
  background-color: rgb(64,68,71);
  font-family: Pricedown Bl;
}

.vips-font {
  font-family: Arial;
}

.bronze {
  color: rgb(205,127,50);
  background-color: rgb(64,68,71);
}

.silver {
  color: rgb(192,192,192);
  background-color: rgb(64,68,71);
}

.gold {
  color: rgb(212,175,55);
  background-color: rgb(64,68,71);
}

.premium {
  color: rgb(185,242,255);
  background-color: rgb(64,68,71);
}

.imgs-bg {
  text-align: center; 
  /*width: 193px;
  height: 53px;*/
  float: center;
  border:3px;
  border-style:hidden solid hidden solid;
  border-color: #000000;
  background-color: rgb(64,68,71);
}

.paypal-btn {
  -webkit-touch-callout: none; /* Disable long press on mobile devices */
  -webkit-user-select: none; /* Disable text selection */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto; /* Re-enable pointer events for links */
}

.paypal-img{
  background-color: rgb(64,68,71);
}

.prices-section {
  background-color: rgb(64,68,71); /* Background color */
  border: 2px solid #000; /* Border style */
  border-radius: 10px; /* Border radius */
  padding: 20px; /* Padding around the content */
  margin-bottom: 20px; /* Margin to separate from other sections */
}


/*     Bottom   */

.bottom {
  text-align: center;
  font-weight: bold;
  font-family: Bahnschrift;
  /*width: 100%;*/
}
/*
.bottom-img {
  width: 260px;
  height: 360px;
}
*/
.bottom-btn {
  color: rgb(255, 255, 255);
  background-color: rgb(112,0,224);
}


/*      Footer      */

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 15px 0;
  flex-shrink: 0;
  /*position: fixed;*/
  /*bottom: 0;
  /*width: 100%;*/
}

.footer-info{
  float: right;
  /*width: 15%;*/
  font-size: 13px;
}

.footer-info p{
  text-align: right;
  margin-bottom: 2px;
  /*width: 15%;*/
  font-size: 11px;
}

.footer-time {
  float: left;
  /*width: 10%;*/
  font-size: 11px;
}

.footer-copy {
  color: white;
  float: center;
  text-align: center;
  /*width: 75%;*/
}


.unselectable {
  -webkit-touch-callout: none; /* Disable long press on mobile devices */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.unselectable a {
  -webkit-touch-callout: none; /* Disable long press on mobile devices */
  -webkit-user-select: none; /* Disable text selection */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto; /* Re-enable pointer events for links */
}

.theme-btn{
  -webkit-touch-callout: none; /* Disable long press on mobile devices */
  -webkit-user-select: none; /* Disable text selection */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto; /* Re-enable pointer events for links */
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
}

.theme-btn:hover{
  background-color: #ffffff;
  color: #333333;
}

.container-h {
  display: flex;
  justify-content: center;
  flex: 1;
}

.container {
  flex: 1;
}

/* Link style for dark theme */
.dark .custom-link {
  color: ghostwhite; 
  text-decoration: none;
}

/* Link style for light theme */
.light .custom-link {
  color: #202020; 
  text-decoration: none;
}

.custom-link:hover {
  color: deepskyblue;
	text-decoration: none;
}

a {
  color: ghostwhite; 
  text-decoration: none;
}

a:hover {
  color: deepskyblue;
	text-decoration: none;
}